import type { FederationRuntimePlugin } from '@module-federation/enhanced/runtime';

const sharedDependencyResolutionPlugin: () => FederationRuntimePlugin = () => ({
  name: 'shared-dependency-resolution-plugin',
  resolveShare(args) {
    const { pkgName, scope, shareScopeMap, version, GlobalFederation } = args;
    const host = GlobalFederation.__INSTANCES__[0];
    args.resolver = function () {
      if (host.options.shared[pkgName]) {
        shareScopeMap[scope][pkgName][version] =
          host.options.shared[pkgName][0];
        return host.options.shared[pkgName][0];
      }
    };
    return args;
  }
});

export default sharedDependencyResolutionPlugin;
